import { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { listOrganizationUsersFullDetails } from "../services/users";
import { UserContext } from "../context";
import { formatDate } from "../utils/utils";

import { useTranslation } from "react-i18next";

export default function StudentsList() {
  const { t } = useTranslation();
  const [listStudents, setListStudents] = useState<any[]>([]);
  const { userInfo } = useContext(UserContext);

  useEffect(() => {
    listOrganizationUsersFullDetails(userInfo?.organizationId)
      .then((records) => {
        // @ts-ignore
        setListStudents(records);
      })
      .catch(console.error);
  }, [userInfo]);

  return (
    <Grid container spacing={2}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="students table">
          <TableHead>
            <TableRow>
              <TableCell>{t("Name")}</TableCell>
              <TableCell>{t("Email")}</TableCell>
              <TableCell>{t("Joined at")}</TableCell>
              <TableCell>{t("Phone")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listStudents.map((student: any) => (
              <TableRow
                key={student.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {student.name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {student.email}
                </TableCell>
                <TableCell component="th" scope="row">
                  {formatDate(student.created)}
                </TableCell>
                <TableCell component="th" scope="row">
                  {"--"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
