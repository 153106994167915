import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import JitsiCourseMeeting from "./course-meeting";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};
type CourseModalProps = {
  openCourseModal: boolean;
  setOpenCourseModal: (value: boolean) => void;
  openedCourse: string;
};
const CourseModal = ({
  openCourseModal,
  setOpenCourseModal,
  openedCourse,
}: CourseModalProps) => {
  return (
    <Modal
      open={openCourseModal}
      onClose={() => {
        setOpenCourseModal(false);
      }}
    >
      <Box sx={style}>
        <IconButton
          size="small"
          onClick={() => {
            setOpenCourseModal(false);
          }}
        >
          <CloseIcon />
        </IconButton>
        <JitsiCourseMeeting openedCourse={openedCourse} />
      </Box>
    </Modal>
  );
};
export default CourseModal;
