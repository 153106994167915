import { pb } from "../utils/pocketbase";
import { CreateCourseFormDataType } from "../utils/types";
export const getCourses = async (organization: string | undefined) => {
  if (!organization) {
    return [];
  }
  const records = await pb.collection("courses").getList(1, 1000, {
    filter: `organization="${organization}"`,
    sort: "-created",
  });
  return records.items;
};

export const deleteCourse = async (courseId: string | null) => {
  if (courseId) {
    await pb.collection("courses").delete(courseId);
  }
};

export const createCourse = async (data: CreateCourseFormDataType) => {
  await pb.collection("courses").create(data);
};

export const updateCourseAttendees = async (
  courseId: string,
  attendeesList: string[],
) => {
  const data = {
    attendees: attendeesList,
  };

  const record = await pb.collection("courses").update(courseId, data);
  console.log(record);
};

export const getCourse = async (courseId: string) => {
  const record = await pb.collection("courses").getOne(courseId);
  return record;
};

export const getCoursesForDate = async (
  organization: string | undefined,
  date: string,
) => {
  if (organization) {
    let filter = `organization="${organization}" && startDate <= "${date} 00:00:00" && endDate >= "${date} 00:00:00"`;
    const records = await pb.collection("courses").getList(1, 1000, {
      filter: filter,
      sort: "-created",
    });

    return records.items;
  }
};
