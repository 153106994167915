import { useState, useMemo } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { orange } from "@mui/material/colors";
import Root from "./routes/root";
import ErrorPage from "./error-page";
import SignIn from "./routes/signin";
import SignUp from "./routes/signup";
import LandingPage from "./routes/landingpage";
import Courses from "./routes/courses";
import Students from "./routes/students";
import Agenda from "./routes/agenda";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ColorModeContext } from "./context";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <LandingPage />,
      },
      {
        path: "signin/",
        element: <SignIn />,
      },
      {
        path: "signup/",
        element: <SignUp />,
      },
      {
        path: "courses/",
        element: <Courses />,
      },
      {
        path: "agenda/",
        element: <Agenda />,
      },
      {
        path: "students/",
        element: <Students />,
      },
    ],
  },
]);

export default function Routes() {
  const [mode, setMode] = useState<"light" | "dark">("dark");
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    [],
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          primary: orange,
        },
      }),
    [mode],
  );
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <RouterProvider router={router} />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
