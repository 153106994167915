import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: {
          Login: "Login",
          "Sign up": "Sign up",
          Email: "Email",
          Password: "Password",
          "Failed to sign in, please verify your email and your password":
            "Failed to sign in, please verify your email and your password",
          "in progress": "in progress",
          programmed: "programmed",
          finished: "finished",
          Name: "Name",
          "start date": "start date",
          "end date": "end date",
          Courses: "Courses",
          Students: "Students",
          Details: "Details",
          "Course meeting": "Course meeting",
          "Add a new course": "Add a new course",
          "Are you sure your want to delete this course?":
            "Are you sure your want to delete this course?",
          "Create a new Course": "Create a new Course",
          Description: "Description",
          "Start Date": "Start Date",
          "End Date": "End Date",
          "Numbre of Seats": "Numbre of Seats",
          Cancel: "Cancel",
          Create: "Create",
          Confirm: "Confirm",
          Schedules: "Schedules",
          "Delete the course ?": "Delete the course ?",
          "Are you sure you want to delete this course ?":
            "Are you sure you want to delete this course ?",
          "Please verify your informations": "Please verify your informations",
          "Students participating at this course":
            "Students participating at this course",
          selected: "selected",
          "Joined at": "Joined at",
          Phone: "Phone",
          "Full Name": "Full Name",
          "Please make sure you correctly filled all fields":
            "Please make sure you correctly filled all the fields",
          "Password is not matching": "Password is not matching",
          "8 characters at least": "8 characters at least",
        },
      },
      fr: {
        translation: {
          Login: "Se connecter",
          "Sign up": "S'inscrire",
          Email: "Email",
          Password: "Mot de passe",
          "Failed to sign in, please verify your email and your password":
            "Impossible de se connecter, veuillez vérifier votre email et votre mot de passe",
          "in progress": "en cours",
          programmed: "programmé",
          finished: "fini",
          Name: "Nom",
          "Start date": "Date de début",
          "End date": "Date de fin",
          Courses: "Cours",
          Students: "Étudiants",
          Details: "Détails",
          "Course meeting": "Réunion",
          "Add a new course": "Ajouter un nouveau cours",
          "Are you sure your want to delete this course?":
            "Etes-vous sûr de vouloir supprimer ce cours ?",
          "Create a new Course": "Créer un nouveau cours",
          Description: "Description",
          "Start Date": "Date de début",
          "End Date": "Date de fin",
          "Numbre of Seats": "Nombre des places",
          Cancel: "Annuler",
          Create: "Créer",
          Confirm: "Confirmer",
          Schedules: "Horaires",
          "Delete the course ?": "Supprimer le cours ?",
          "Are you sure you want to delete this course ?":
            "Etes-vous sûr de vouloir supprimer ce cours ?",

          "Please verify your informations":
            "Veuillez vérifier vos informations",
          "Students participating at this course":
            "Étudiants participant à ce cours",
          selected: "sélectionné",
          "Joined at": "Inscrit à",
          Phone: "Téléphone",
          "Full Name": "Nom et Prénom",
          "Please make sure you correctly filled all the fields":
            "Veuillez vous assurer que vous avez correctement rempli tous les champs",
          "Password is not matching": "Le mot de passe ne correspond pas",
          "8 characters at least": "8 caractères au moins",
        },
      },
    },
    lng: "fr", // if you're using a language detector, do not define the lng option
    fallbackLng: "fr",

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

// i18n.changeLanguage("fr");

export default i18n;
