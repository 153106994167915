import Brightness3Icon from "@mui/icons-material/Brightness3";
import LightModeIcon from "@mui/icons-material/LightMode";
import LogoutIcon from "@mui/icons-material/Logout";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import {
  ColorModeContext,
  GlobalOrganizationNameContext,
  UserContext,
} from "../context";
export default function Bar() {
  const { t } = useTranslation();
  const userInfo = useContext(UserContext);
  const globalOrganizationName = useContext(GlobalOrganizationNameContext);
  const navigate = useNavigate();
  return (
    <AppBar
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Toolbar>
        <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
          <Link to={"/"} style={{ textDecoration: "none", color: "inherit" }}>
            {globalOrganizationName}
          </Link>
        </Typography>
        {userInfo.userInfo ? (
          <div>{`${userInfo.userInfo.authStore.model.name ? userInfo.userInfo.authStore.model.name : userInfo.userInfo.authStore.model.email}`}</div>
        ) : (
          <>
            <Link
              to={"/signin/"}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Button color="inherit">{t("Login")}</Button>
            </Link>
            <Link
              to={"/signup/"}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Button color="inherit">{t("Sign up")}</Button>
            </Link>
          </>
        )}
        {userInfo.userInfo && (
          <IconButton
            aria-label="logout"
            onClick={() => {
              console.log("logging out");
              userInfo.userInfo?.authStore?.clear();
              if (userInfo.setUserInfo) {
                userInfo.setUserInfo(null);
              }
              navigate("../");
              console.log("logged out");
            }}
          >
            <LogoutIcon />
          </IconButton>
        )}
        <ToggleTheme />
      </Toolbar>
    </AppBar>
  );
}

const ToggleTheme = () => {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  return (
    <>
      <IconButton
        sx={{ ml: 1 }}
        onClick={colorMode.toggleColorMode}
        color="inherit"
      >
        {theme.palette.mode === "dark" ? (
          <LightModeIcon />
        ) : (
          <Brightness3Icon />
        )}
      </IconButton>
    </>
  );
};
