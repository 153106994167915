import { useState, useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { CreateCourseFormDataType } from "../utils/types";
import { useTranslation } from "react-i18next";
import { UserContext } from "../context";
import { createCourse } from "../services/courses";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Alert from "@mui/material/Alert";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

type CreateCourseModalProps = {
  openCreateCourseModal: boolean;
  setOpenCreateCourseModal: (value: boolean) => void;
};

const CreateCourseModal = ({
  openCreateCourseModal,
  setOpenCreateCourseModal,
}: CreateCourseModalProps) => {
  const userInfo = useContext(UserContext);
  const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
  const [formData, setFormData] = useState<CreateCourseFormDataType>({
    name: "",
    description: "",
    startDate: "",
    endDate: "",
    organization: userInfo.userInfo?.organizationId,
    seats: 10,
    schedules: "example:\nlundi: 10:00 a 11:30",
  });
  const { t } = useTranslation();

  const controlFormData = () => {
    let control = true;
    if (
      !formData.name ||
      !formData.description ||
      !formData.startDate ||
      !formData.endDate ||
      !formData.seats ||
      !formData.schedules
    ) {
      return false;
    }
    return control;
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (!controlFormData()) {
      setShowErrorAlert(true);
      return;
    }
    createCourse(formData).then(() => {
      setFormData({
        name: "",
        description: "",
        startDate: "",
        endDate: "",
        organization: userInfo.userInfo?.organizationId,
        seats: 10,
        schedules: "example: lundi: 10:00 a 11:30",
      });
      setOpenCreateCourseModal(false);
    });
  };
  const handleChange = (event: any) => {
    setFormData({ ...formData, [event.target.id]: event.target.value });
  };
  return (
    <Modal
      open={openCreateCourseModal}
      onClose={() => {
        setOpenCreateCourseModal(false);
        setShowErrorAlert(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={style}
        component="form"
        onSubmit={handleSubmit}
        method="post"
        noValidate
        autoComplete="off"
      >
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ mb: 2 }}
        >
          {t("Create a new Course")}
        </Typography>
        <Grid container spacing={2}>
          {showErrorAlert && (
            <Grid item xs={12}>
              <Alert
                severity="error"
                onClose={() => {
                  setShowErrorAlert(false);
                }}
              >
                {t("Please verify your informations")}
              </Alert>
            </Grid>
          )}
          <Grid item xs={10}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField
                  required
                  fullWidth
                  id="name"
                  label={t("Name")}
                  margin="normal"
                  value={formData.name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  required
                  fullWidth
                  id="description"
                  label={t("Description")}
                  margin="normal"
                  value={formData.description}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      sx={{ width: "100%" }}
                      label={t("Start Date")}
                      format="YYYY-MM-DD"
                      onChange={(event) => {
                        setFormData({
                          ...formData,
                          // @ts-ignore
                          ["startDate"]: event?.format("YYYY-MM-DD"),
                        });
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      sx={{ width: "100%" }}
                      label={t("End Date")}
                      format="YYYY-MM-DD"
                      onChange={(event) => {
                        setFormData({
                          ...formData,
                          // @ts-ignore
                          ["endDate"]: event?.format("YYYY-MM-DD"),
                        });
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  required
                  fullWidth
                  id="seats"
                  label={t("Numbre of Seats")}
                  margin="dense"
                  value={formData.seats}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  multiline
                  rows={5}
                  id="schedules"
                  label={t("Schedules")}
                  margin="normal"
                  value={formData.schedules}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div style={{ textAlign: "center" }}>
              <Button
                variant="outlined"
                sx={{ m: "20px" }}
                onClick={() => {
                  setOpenCreateCourseModal(false);
                  setShowErrorAlert(false);
                }}
              >
                {t("Cancel")}
              </Button>
              <Button variant="contained" sx={{ m: "20px" }} type="submit">
                {t("Create")}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
export default CreateCourseModal;
