import { pb } from "../utils/pocketbase";

export const listOrganizationUsers = async (
  organizationId: string | undefined,
) => {
  const resultList = await pb.collection("users").getList(1, 1000, {
    filter: `organization="${organizationId}" && role="user"`,
    sort: "name",
  });
  let usersIds: any[] = [];
  let idNameMapping: any = {};
  resultList.items.forEach((item: any) => {
    usersIds.push(item.id);
    idNameMapping[item.id] = item.name;
  });
  return { usersIds, idNameMapping };
};

export const listCourseAttendees = async (courseId: string) => {
  const records = await pb.collection("courses").getList(1, 1000, {
    filter: `id="${courseId}"`,
    sort: "-created",
    expand: "attendees",
  });
  let attendeesIds = records.items[0].attendees;
  return attendeesIds;
};

export const getUserDetails = async (userId: string) => {
  const record = await pb.collection("users").getOne(userId);
  return record;
};

export const listOrganizationUsersFullDetails = async (
  organizationId: string | undefined,
) => {
  const records = await pb.collection("users").getList(1, 1000, {
    filter: `organization="${organizationId}" && role="user"`,
    sort: "name",
  });
  return records.items;
};

export const getOrganizationId = async (organizationName: string) => {
  const record = await pb
    .collection("organization")
    .getFirstListItem(`name="${organizationName}"`);
  return record;
};
