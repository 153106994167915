import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { SignupDataType } from "../utils/types";
import { useNavigate } from "react-router-dom";
import { pb } from "../utils/pocketbase";
import { useContext } from "react";
import { GlobalOrganizationNameContext } from "../context";
import { emailIsValid } from "../utils/utils";
import { getOrganizationId } from "../services/users";

import Alert from "@mui/material/Alert";

export default function SignUp() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const globalOrganizationName = useContext(GlobalOrganizationNameContext);

  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [signupFailed, setSignupFailed] = useState(false);
  const [failingMessage, setFailingMessage] = useState("");
  const [formData, setFormData] = useState<SignupDataType>({
    name: "",
    email: "",
    password: "",
  });

  const handleChange = (event: any) => {
    setFormData({ ...formData, [event.target.id]: event.target.value });
  };

  const controlOk = () => {
    if (
      !formData.name ||
      !formData.email ||
      !formData.password ||
      !passwordConfirmation ||
      !emailIsValid(formData.email)
    ) {
      setSignupFailed(true);
      setFailingMessage(
        t("Please make sure you correctly filled all the fields"),
      );
      return false;
    }
    if (passwordConfirmation !== formData.password) {
      setSignupFailed(true);
      setFailingMessage(t("Password is not matching"));
      return false;
    }
    return true;
  };
  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!controlOk()) {
      return false;
    }

    getOrganizationId(globalOrganizationName)
      .then(async (result) => {
        const data = {
          email: formData.email,
          emailVisibility: true,
          password: formData.password,
          passwordConfirm: passwordConfirmation,
          name: formData.name,
          role: "user",
          organization: result.id,
        };

        const record = await pb.collection("users").create(data);
        if (record) {
          navigate("../signin/");
        }
      })
      .catch(console.error);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      method="post"
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "50ch",
        alignItems: "center",
        m: "auto",
        mt: "7ch",
      }}
      noValidate
      autoComplete="off"
    >
      <Typography variant="h4" sx={{ mb: 4 }}>
        {t("Sign up")}
      </Typography>
      {signupFailed && (
        <Alert
          severity="error"
          onClose={() => {
            setSignupFailed(false);
          }}
        >
          {failingMessage}
        </Alert>
      )}
      <TextField
        required
        fullWidth
        id="name"
        label={t("Full Name")}
        margin="normal"
        value={formData.name}
        onChange={handleChange}
      />
      <TextField
        required
        fullWidth
        id="email"
        label={t("Email")}
        margin="normal"
        value={formData.email}
        onChange={handleChange}
      />
      <TextField
        required
        fullWidth
        id="password"
        label={t("Password")}
        type="password"
        margin="normal"
        helperText={t("8 characters at least")}
        value={formData.password}
        onChange={handleChange}
      />
      <TextField
        required
        fullWidth
        id="confirm-password"
        label={`${t("Confirm")} ${t("Password")}`}
        type="password"
        margin="normal"
        value={passwordConfirmation}
        onChange={(event) => {
          setPasswordConfirmation(event.target.value);
        }}
      />
      <Button
        variant="contained"
        sx={{ mt: "20px" }}
        type="submit"
        size="large"
      >
        {t("Sign up")}
      </Button>
    </Box>
  );
}
