import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { getCourses } from "../services/courses";
import CoursesTable from "../components/courses";
import { useContext } from "react";
import { UserContext } from "../context";

export default function Courses() {
  const [courses, setCourses] = useState([]);
  const userInfo = useContext(UserContext);

  useEffect(() => {
    getCourses(userInfo.userInfo?.organizationId)
      .then((result) => {
        // @ts-ignore
        setCourses(result);
      })
      .catch(console.error);
  }, [courses, userInfo]);

  return (
    <Box>
      <Container sx={{ mt: 7 }}>
        <Box sx={{ width: "100%" }}>
          <CoursesTable courses={courses} />
        </Box>
      </Container>
    </Box>
  );
}
