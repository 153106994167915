export const formatDate = (date: string) => {
  return date.split(" ")[0];
};

export const detectOrganizationFromUrl = () => {
  let result = window.location.host.split(".")[0];
  console.log("result => ", result);
  if (result === "localhost:3000") {
    return "norma";
  }
  return result;
};

export const emailIsValid = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
