import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Bar from "../components/bar";
import { Outlet } from "react-router-dom";
import { UserContext, GlobalOrganizationNameContext } from "../context";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import LandingPage from "./landingpage";
import { detectOrganizationFromUrl } from "../utils/utils";
import NavigationDrawer from "../components/navigation-drawer";
import CssBaseline from "@mui/material/CssBaseline";

export default function Root() {
  const location = useLocation();
  const { hash, pathname, search } = location;
  const [userInfo, setUserInfo] = useState(null);
  console.log(hash, pathname, search);

  let detectedOrganization = detectOrganizationFromUrl();
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <UserContext.Provider value={{ userInfo, setUserInfo }}>
        <GlobalOrganizationNameContext.Provider value={detectedOrganization}>
          <Bar />
          <Container sx={{ mt: 4 }}>
            {userInfo ? (
              <>
                <NavigationDrawer />
                <Outlet />
              </>
            ) : pathname === "/signin/" || pathname === "/signup/" ? (
              <Outlet />
            ) : (
              <LandingPage />
            )}
          </Container>
        </GlobalOrganizationNameContext.Provider>
      </UserContext.Provider>
    </Box>
  );
}
