import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import PeopleIcon from "@mui/icons-material/People";
import EventIcon from "@mui/icons-material/Event";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const drawerWidth = 240;

const NavigationDrawer = () => {
  const { t } = useTranslation();
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: "auto" }}>
        <List>
          <NavLink
            to={"/courses/"}
            className={({ isActive, isPending }) =>
              isActive ? "active" : isPending ? "pending" : "not_active"
            }
          >
            <ListItem key={"courses"} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <LibraryBooksIcon />
                </ListItemIcon>
                <ListItemText primary={t("Courses")} />
              </ListItemButton>
            </ListItem>
          </NavLink>
          <NavLink
            to={"/agenda/"}
            className={({ isActive, isPending }) =>
              isActive ? "active" : isPending ? "pending" : "not_active"
            }
          >
            <ListItem key={"students"} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <EventIcon />
                </ListItemIcon>
                <ListItemText primary={"Agenda"} />
              </ListItemButton>
            </ListItem>
          </NavLink>
          <NavLink
            to={"/students/"}
            className={({ isActive, isPending }) =>
              isActive ? "active" : isPending ? "pending" : "not_active"
            }
          >
            <ListItem key={"students"} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary={t("Students")} />
              </ListItemButton>
            </ListItem>
          </NavLink>
        </List>
        <Divider />
      </Box>
    </Drawer>
  );
};

export default NavigationDrawer;
