import { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { UserContext } from "../context";

import dayjs from "dayjs";
import CourseModal from "../components/course-modal";
import IconButton from "@mui/material/IconButton";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useTranslation } from "react-i18next";
import { getCoursesForDate } from "../services/courses";

type CoursesTableProps = {
  listDateCourses: any[];

  setOpenedCourse: (value: string) => void;
  setOpenCourseModal: (value: boolean) => void;
};

type SelectedDateCalendarProps = {
  setSelectedDate: (value: string) => void;
};

export default function Agenda() {
  const { userInfo } = useContext(UserContext);
  const [listDateCourses, setListDateCourses] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    dayjs().format("YYYY-MM-DD"),
  );

  const [openedCourse, setOpenedCourse] = useState("");
  const [openCourseModal, setOpenCourseModal] = useState<boolean>(false);
  useEffect(() => {
    getCoursesForDate(userInfo?.organizationId, selectedDate)
      .then((records) => {
        if (records) {
          // @ts-ignore
          setListDateCourses(records);
        }
      })
      .catch(console.error);
  }, [selectedDate, userInfo]);
  return (
    <Box>
      <Container sx={{ mt: 11 }}>
        <Box sx={{ width: "100%" }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Paper>
                <SelectedDateCalendar setSelectedDate={setSelectedDate} />
              </Paper>
            </Grid>
            {selectedDate && (
              <Grid item xs={8}>
                <CoursesTable
                  listDateCourses={listDateCourses}
                  setOpenedCourse={setOpenedCourse}
                  setOpenCourseModal={setOpenCourseModal}
                />
              </Grid>
            )}

            {openCourseModal && (
              <CourseModal
                openCourseModal={openCourseModal}
                setOpenCourseModal={setOpenCourseModal}
                openedCourse={openedCourse}
              />
            )}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
export function SelectedDateCalendar({
  setSelectedDate,
}: SelectedDateCalendarProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar
        defaultValue={dayjs()}
        onChange={(event) => {
          setSelectedDate(event?.format("YYYY-MM-DD"));
        }}
      />
    </LocalizationProvider>
  );
}

export function CoursesTable({
  listDateCourses,
  setOpenedCourse,
  setOpenCourseModal,
}: CoursesTableProps) {
  const { t } = useTranslation();
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{t("Name")}</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listDateCourses.map((course) => (
            <TableRow
              key={""}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {course.name}
              </TableCell>
              <TableCell align="right">
                <IconButton
                  onClick={() => {
                    setOpenedCourse(course.name);
                    setOpenCourseModal(true);
                  }}
                >
                  <VideoCallIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
