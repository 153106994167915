import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { CourseType } from "../utils/types";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import { deleteCourse } from "../services/courses";
import CreateCourseModal from "../components/create-course-modal";
import CourseModal from "../components/course-modal";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import PeopleIcon from "@mui/icons-material/People";
import InfoIcon from "@mui/icons-material/Info";
import { formatDate } from "../utils/utils";
import CourseAttendeesModal from "../components/course-attendees";

type CoursesTableProps = {
  courses: CourseType[];
};

type DeleteCourseDialogProps = {
  openConfirmDialog: boolean;
  setOpenConfirmDialog: (value: boolean) => void;
  courseIdToDelete: string | null;
};

const CoursesTable = ({ courses }: CoursesTableProps) => {
  const [openCreateCourseModal, setOpenCreateCourseModal] =
    useState<boolean>(false);
  const [openCourseModal, setOpenCourseModal] = useState<boolean>(false);
  const { t } = useTranslation();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [courseIdToDelete, setCourseIdToDelete] = useState<string | null>(null);
  const [openedCourse, setOpenedCourse] = useState("");
  const [openedCourseId, setOpenedCourseId] = useState("");
  const [openCourseAttendeesModal, setOpenCourseAttendeesModal] =
    useState<boolean>(false);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div style={{ textAlign: "right" }}>
          <Button
            variant="contained"
            onClick={() => {
              setOpenCreateCourseModal(true);
            }}
          >
            {t("Add a new course")}
          </Button>
        </div>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="courses table">
            <TableHead>
              <TableRow>
                <TableCell>{t("Name")}</TableCell>
                <TableCell align="right">{t("Start date")}</TableCell>
                <TableCell align="right">{t("End date")}</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {courses.map((course) => (
                <TableRow
                  key={course.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {course.name}
                  </TableCell>
                  <TableCell align="right">
                    {formatDate(course.startDate)}
                  </TableCell>
                  <TableCell align="right">
                    {formatDate(course.endDate)}
                  </TableCell>
                  <TableCell align="right">
                    <Stack
                      direction="row"
                      spacing={2}
                      justifyContent="flex-end"
                    >
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setOpenedCourseId(course.id);
                          setOpenCourseAttendeesModal(true);
                        }}
                      >
                        <PeopleIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          setOpenedCourse(course.name);
                          setOpenCourseModal(true);
                        }}
                      >
                        <VideoCallIcon />
                      </IconButton>
                      <IconButton
                        style={{ color: "red" }}
                        onClick={() => {
                          setCourseIdToDelete(course.id);
                          setOpenConfirmDialog(true);
                        }}
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {openCreateCourseModal && (
        <CreateCourseModal
          openCreateCourseModal={openCreateCourseModal}
          setOpenCreateCourseModal={setOpenCreateCourseModal}
        />
      )}
      {openCourseModal && (
        <CourseModal
          openCourseModal={openCourseModal}
          setOpenCourseModal={setOpenCourseModal}
          openedCourse={openedCourse}
        />
      )}
      {openCourseAttendeesModal && (
        <CourseAttendeesModal
          openCourseAttendeesModal={openCourseAttendeesModal}
          setOpenCourseAttendeesModal={setOpenCourseAttendeesModal}
          openedCourseId={openedCourseId}
        />
      )}
      {openConfirmDialog && (
        <DeleteCourseDialog
          openConfirmDialog={openConfirmDialog}
          setOpenConfirmDialog={setOpenConfirmDialog}
          courseIdToDelete={courseIdToDelete}
        />
      )}
    </Grid>
  );
};
export default CoursesTable;

const DeleteCourseDialog = ({
  openConfirmDialog,
  setOpenConfirmDialog,
  courseIdToDelete,
}: DeleteCourseDialogProps) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={openConfirmDialog}
      onClose={() => {
        setOpenConfirmDialog(false);
      }}
    >
      <DialogTitle id="id-delete-course-dialog-title">
        {t("Delete the course ?")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="id-delete-course-dialog-message">
          {t("Are you sure you want to delete this course ?")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpenConfirmDialog(false);
          }}
        >
          {t("Cancel")}
        </Button>
        <Button
          onClick={() => {
            if (courseIdToDelete) {
              deleteCourse(courseIdToDelete);
              setOpenConfirmDialog(false);
            }
          }}
        >
          {t("Confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
