import { createContext } from "react";
import { UserContextType } from "./utils/types";

export const UserContext = createContext<UserContextType>({
  userInfo: null,
  setUserInfo: null,
});

export const GlobalOrganizationNameContext = createContext("");

export const ColorModeContext = createContext({ toggleColorMode: () => {} });
