import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import StudentsList from "../components/students";

export default function Students() {
  return (
    <Box>
      <Container sx={{ mt: 11 }}>
        <Box sx={{ width: "100%" }}>
          <StudentsList />
        </Box>
      </Container>
    </Box>
  );
}
