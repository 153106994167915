import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { SigninDataType } from "../utils/types";
import { useNavigate } from "react-router-dom";
import { pb } from "../utils/pocketbase";
import { useContext } from "react";
import { UserContext } from "../context";
import { detectOrganizationFromUrl } from "../utils/utils";

import Alert from "@mui/material/Alert";

export default function SignIn() {
  const { t } = useTranslation();
  const userInfo = useContext(UserContext);
  const navigate = useNavigate();
  const [formData, setFormData] = useState<SigninDataType>({
    email: "",
    password: "",
  });
  const [signinFailed, setSigninFailed] = useState(false);

  const handleChange = (event: any) => {
    setFormData({ ...formData, [event.target.id]: event.target.value });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    let detectedOrganization = detectOrganizationFromUrl();
    await pb
      .collection("users")
      .authWithPassword(formData.email, formData.password)
      .then(async () => {
        let organizationId = pb.authStore.model?.organization;

        await pb
          .collection("organization")
          .getOne(organizationId, {
            expand: "name",
          })
          .then((result) => {
            if (result.name !== detectedOrganization) {
              pb.authStore.clear();
              console.log("don't belong to this organization");
              setSigninFailed(true);
            } else {
              if (userInfo.setUserInfo) {
                userInfo.setUserInfo({
                  authStore: pb.authStore,
                  organzationName: result.name,
                  organizationId: organizationId,
                });
                navigate("../courses/");
              }
            }
          });
      })
      .catch((error) => {
        console.log("failed signin");
        setSigninFailed(true);
      });
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      method="post"
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "50ch",
        alignItems: "center",
        m: "auto",
        mt: "7ch",
      }}
      noValidate
      autoComplete="off"
    >
      <Typography variant="h4" sx={{ mb: 4 }}>
        {t("Login")}
      </Typography>
      {signinFailed && (
        <Alert
          severity="error"
          onClose={() => {
            setSigninFailed(false);
          }}
        >
          {t("Failed to sign in, please verify your email and your password")}
        </Alert>
      )}
      <TextField
        required
        fullWidth
        id="email"
        label={t("Email")}
        margin="normal"
        value={formData.email}
        onChange={handleChange}
      />
      <TextField
        required
        fullWidth
        id="password"
        label={t("Password")}
        type="password"
        margin="normal"
        value={formData.password}
        onChange={handleChange}
      />
      <Button
        variant="contained"
        sx={{ mt: "20px" }}
        type="submit"
        size="large"
      >
        {t("Login")}
      </Button>
    </Box>
  );
}
