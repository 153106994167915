import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

export default function LandingPage() {
  console.log(window.location.hostname.split(".")[0]);
  return (
    <Box>
      <Container sx={{ mt: 4 }}>landing page</Container>
    </Box>
  );
}
