import { JitsiMeeting } from "@jitsi/react-sdk";
import { useContext } from "react";
import { UserContext } from "../context";

type JitsiCourseMeetingProps = {
  openedCourse: string;
};
export default function JitsiCourseMeeting({
  openedCourse,
}: JitsiCourseMeetingProps) {
  const { userInfo } = useContext(UserContext);
  return (
    <JitsiMeeting
      domain={process.env.REACT_APP_JITSI_URL}
      roomName={openedCourse}
      configOverwrite={{
        startWithAudioMuted: false,
        disableModeratorIndicator: true,
        startScreenSharing: true,
        enableEmailInStats: false,
      }}
      interfaceConfigOverwrite={{
        DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
      }}
      userInfo={{
        displayName: userInfo ? userInfo.authStore.model.name : "",
        email: userInfo ? userInfo.authStore.model.email : "",
      }}
      onApiReady={(externalApi) => {
        // here you can attach custom event listeners to the Jitsi Meet External API
        // you can also store it locally to execute commands
      }}
      getIFrameRef={(iframeRef) => {
        iframeRef.style.height = "90vh";
      }}
    />
  );
}
