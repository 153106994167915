import { useState, useEffect, ReactNode, useContext } from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { UserContext } from "../context";
import { updateCourseAttendees, getCourse } from "../services/courses";
import { listOrganizationUsers, listCourseAttendees } from "../services/users";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
};

type CourseAttendeesModalProps = {
  openCourseAttendeesModal: boolean;
  setOpenCourseAttendeesModal: (value: boolean) => void;
  openedCourseId: string;
};

type StudentsAttendingListProps = {
  openedCourseId: string;
  right: string[];
  setRight: (value: string[]) => void;
};
function not(a: string[], b: string[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: string[], b: string[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a: string[], b: string[]) {
  return [...a, ...not(b, a)];
}

export function StudentsAttendingList({
  openedCourseId,
  right,
  setRight,
}: StudentsAttendingListProps) {
  const [checked, setChecked] = useState<string[]>([]);
  const [left, setLeft] = useState<string[]>([]);
  const [temporaryLeft, setTemporaryLeft] = useState<string[]>([]);
  const [listUsersIdNameMapping, setListUsersIdNameMapping] = useState<any>({});
  const { userInfo } = useContext(UserContext);
  const { t } = useTranslation();

  useEffect(() => {
    listOrganizationUsers(userInfo?.organizationId)
      .then((result) => {
        setTemporaryLeft(result.usersIds);
        setListUsersIdNameMapping(result.idNameMapping);
      })
      .catch(console.error);

    listCourseAttendees(openedCourseId)
      .then((result) => {
        setRight(result);
      })
      .catch(console.error);
  }, [userInfo, openedCourseId, setRight]);
  useEffect(() => {
    let cleanLeft = temporaryLeft.filter((n) => !right.includes(n));
    setLeft(cleanLeft);
  }, [temporaryLeft, right]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: string[]) =>
    intersection(checked, items).length;

  const handleToggleAll = (items: string[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const customList = (title: ReactNode, items: string[]) => (
    <Card variant="outlined">
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} ${t("selected")}`}
      />
      <Divider />
      <List
        sx={{
          width: 250,
          height: 430,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value: string) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItemButton
              key={value}
              role="listitem"
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={listUsersIdNameMapping[value]}
              />
            </ListItemButton>
          );
        })}
      </List>
    </Card>
  );

  return (
    <Grid
      container
      spacing={2}
      sx={{ justifyContent: "center", alignItems: "center" }}
    >
      <Grid item>{customList(t("Students"), left)}</Grid>
      <Grid item>
        <Grid container direction="column" sx={{ alignItems: "center" }}>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        {customList(t("Students participating at this course"), right)}
      </Grid>
    </Grid>
  );
}

export default function CourseAttendeesModal({
  openCourseAttendeesModal,
  setOpenCourseAttendeesModal,
  openedCourseId,
}: CourseAttendeesModalProps) {
  const { t } = useTranslation();

  const [right, setRight] = useState<string[]>([]);
  const [courseData, setCourseData] = useState<any>({});
  useEffect(() => {
    getCourse(openedCourseId)
      .then((course) => {
        setCourseData(course);
      })
      .catch(console.log);
  }, [courseData, openedCourseId]);
  return (
    <Modal
      open={openCourseAttendeesModal}
      onClose={() => {
        setOpenCourseAttendeesModal(false);
      }}
    >
      <Box sx={style}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" component="h2">
              {courseData.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <StudentsAttendingList
              openedCourseId={openedCourseId}
              right={right}
              setRight={setRight}
            />
          </Grid>

          <Grid item xs={12}>
            <div style={{ textAlign: "center" }}>
              <Button
                variant="outlined"
                sx={{ m: "20px" }}
                onClick={() => {
                  setOpenCourseAttendeesModal(false);
                }}
              >
                {t("Cancel")}
              </Button>
              <Button
                variant="contained"
                sx={{ m: "20px" }}
                type="submit"
                onClick={() => {
                  updateCourseAttendees(openedCourseId, right)
                    .then(() => {
                      setOpenCourseAttendeesModal(false);
                    })
                    .catch(console.error);
                }}
              >
                {t("Confirm")}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
